<template>
  <div class="today-orders">
    <section class="today-orders-filtering-area mb-2">
      <b-row align-v="center">
        <b-col lg="4" md="6" class="d-flex align-items-center">
          <div class="today-orders-filtering-area__today-orders-title mb-1 mr-2">
            طلبات اليوم
          </div>
          <div class="today-orders-filtering-area__filter-title mb-1">فلتر حسب:</div>
        </b-col>

        <b-col
          lg="4"
          md="6"
          class="today-orders-filtering-area__filter-by-order-status-container d-flex"
        >
          <div class="today-orders-filtering-area__order-status-type mr-1">
            نوع الطلب:
          </div>
          <ek-input-select
            clearable
            v-model="filter.status"
            :options="orderTypesList"
            name="statusFtiler"
            class="today-orders-filtering-area__order-status-input"
          />
        </b-col>
      </b-row>
    </section>

    <section class="orders-table">
      <ek-table
        :items="todayOrdersList.filter(filterTodayOrders)"
        :columns="columns"
        @details="navigateToOrderDetails"
        @delete-selected="deleteOrders"
      >
        <template slot="items.requestDate" slot-scope="{ value }">
          {{ value ? new Date(value).toLocaleTimeString("en-UK") : "-" }}
        </template>
        <template slot="items.requestType" slot-scope="{ value }">
          {{ orderTypesList.find((item) => item.number == value).name }}
        </template>
        <template slot="items.requestStatus" slot-scope="{ value }">
          <StatusBadge
            class="orders-table__status-badge"
            :statusList="orderStatusList"
            :selectedStatusNumber="value"
          />
        </template>
      </ek-table>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge";

export default {
  computed: {
    ...mapGetters(["todayOrdersList", "activitesList"]),
    ...mapState({
      orderStatusList: (state) => state.orders.orderStatusList,
    }),
  },
  components: {
    StatusBadge,
  },
  created() {
    this.getOrdersList();
  },
  data: () => ({
    filter: {
      status: null,
    },
    orderTypesList: [
      {
        id: 1,
        number: 1,
        name: "طلب إعلان",
        route: "/admin/orders/adsRequest",
      },
      {
        id: 2,
        number: 2,
        name: "طلب إشعار",
        route: "/admin/orders/notificationRequest",
      },
      {
        id: 3,
        number: 3,
        name: "طلب توظيف",
        route: "/admin/orders/jobRequest",
      },
    ],
    columns: [
      {
        label: " اسم الفعالية",
        field: "activityName",
      },
      {
        label: "النشاط التجاري",
        field: "comertialAcitvityName",
      },
      {
        label: "المدينة",
        field: "cityName",
      },
      {
        label: "نوع الطلب",
        field: "requestType",
      },
      {
        label: "توقيت الطلب",
        field: "requestDate",
      },
      {
        label: "حالة الطلب",
        field: "requestStatus",
      },
      {
        label: "تفاصيل",
        field: "details",
        sortable: false,
      },
    ],
  }),
  methods: {
    ...mapActions(["getOrdersList", "deleteRangeOrders"]),
    ...mapMutations(["Set_Filter_Dto", "Reset_Orders_Dto"]),
    navigateToOrderDetails({ row }) {
      let routeName = this.orderTypesList.find((item) => item.number == row.requestType)
        .route;

      this.$router.push(`${routeName}/${row.id}`);  
    },
    beforeDestroy() {
      this.$store.commit("Reset_Search_Dto");
    },
    filterTodayOrders(item) {
      return item.requestType == this.filter.status || !this.filter.status;
    },
    deleteOrders(e) {

        let removeList = this.todayOrdersList.filter(item => e.includes(item.id))
                                             .map(item => ({id: item.id, requestTypes: item.requestType}))


        console.log("removeList", removeList)
    
        this.deleteRangeOrders(removeList)
        
    },
  },
};
</script>

<style lang="scss" scoped>
.today-orders-filtering-area {
  align-items: center;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
  }

  &__today-orders-title {
    font-size: 1.5rem;
  }

  &__filter-title {
    font-weight: bold;
    font-size: 1.2rem;

    color: #6e6b7b;
  }

  &__filter-container {
    display: flex;
    gap: 2rem;
  }

  &__filter-by-activity-container {
    display: flex;
  }

  &__activity-filter-title {
    margin-top: 1.4rem;
    font-size: 1.2rem;

    color: #6e6b7b;
  }

  &__activity-filter-input {
    flex: 1;
  }

  &__filter-by-orders-status-container {
    display: flex;
  }

  &__filter-by-order-date-container {
    margin-top: 0.6rem;
  }

  &__filter-by-order-status-container {
    margin-top: -0.5rem;
  }

  &__order-date-title {
    margin-top: 0.6rem;

    font-size: 1.2rem;

    color: #6e6b7b;

    @media only screen and (max-width: 780px) {
      margin-left: 0px;
    }
  }

  &__order-date-input {
    margin-top: -0.9rem;
    flex: 1;
  }

  &__order-status-type {
    margin-top: 1.3rem;

    font-size: 1.2rem;

    color: #6e6b7b;

    @media only screen and (max-width: 780px) {
      margin-left: 0px;
    }
  }

  &__order-status-input {
    flex: 1;
  }
}
</style>
