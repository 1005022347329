<template>
    <div class="orders">
        <section class="orders-title-filtering-area mb-2">
            <b-row class="orders-title-filtering-area__container">
                <b-col lg="4">
                    <b-button
                        variant="outline-primary"
                        @click="setNotificationTabActive"
                        class="orders-title-filtering-area__filter-button d-flex justify-content-center align-items-center"
                        :class="{
                            'bg-primary text-white': notificationTabIsActive,
                        }"
                    >
                        <unicon
                            height="22"
                            width="22"
                            name="bell"
                            class="svg-primary mr-1"
                            :class="{ 'svg-light': notificationTabIsActive }"
                        />
                        <div>
                            طلبات الإشعار
                            {{ "(" + notificationOrdersCount + ")" }}
                        </div>
                    </b-button>
                </b-col>
                <b-col lg="4">
                    <b-button
                        variant="outline-primary orders-title-filtering-area__filter-button d-flex justify-content-center align-items-center"
                        @click="setAdsTabActive"
                        :class="{ 'bg-primary text-white': adsTabIsActive }"
                    >
                        <unicon
                            height="22"
                            width="22"
                            name="tv-retro"
                            class="svg-primary mr-1"
                            :class="{ 'svg-light': adsTabIsActive }"
                        />
                        <div>
                            طلبات الإعلان {{ "(" + adsOrdersCount + ")" }}
                        </div>
                    </b-button>
                </b-col>
                <b-col lg="4">
                    <b-button
                        variant="outline-primary orders-title-filtering-area__filter-button d-flex justify-content-center align-items-center"
                        @click="setJobTabActive"
                        :class="{ 'bg-primary text-white': jobTabIsActive }"
                    >
                        <unicon
                            height="22"
                            width="22"
                            name="briefcase"
                            class="svg-primary mr-1"
                            :class="{ 'svg-light': jobTabIsActive }"
                        />
                        <div>
                            طلبات التوظيف {{ "(" + jobOrdersCount + ")" }}
                        </div>
                    </b-button>
                </b-col>
            </b-row>
        </section>
        <component v-bind:is="selectOrderTable" />
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import AdsOrders from "./components/AdsOrders.vue";
import notificationOrders from "./components/NotificationOrders.vue";
import jobOrders from "./components/JobOrders.vue";
import todayOrders from "./components/TodayOrders.vue";

export default {
    components: {
        AdsOrders,
        jobOrders,
        notificationOrders,
        todayOrders,
    },
    computed: {
        ...mapGetters([
            "ordersList",
            "activitesList",
            "notificationOrdersCount",
            "adsOrdersCount",
            "jobOrdersCount",
        ]),
        ...mapState({
            activeOrderTab: (state) => state.orders.activeOrderTab,
        }),
        selectOrderTable() {
            let component = "";
            switch (this.activeOrderTab) {
                case -1:
                    component = todayOrders;
                    break;
                case 0:
                    component = notificationOrders;
                    break;
                case 1:
                    component = AdsOrders;
                    break;
                case 2:
                    component = jobOrders;
                    break;
                default:
                    component = todayOrders;
            }
            return component;
        },
        notificationTabIsActive() {
            return this.activeOrderTab == 0;
        },
        adsTabIsActive() {
            return this.activeOrderTab == 1;
        },
        jobTabIsActive() {
            return this.activeOrderTab == 2;
        },
    },
    data: () => ({
        columns: [
            {
                label: " اسم الفعالية",
                field: "accountName",
            },
            {
                label: "النشاط التجاري",
                field: "comertaialAcitvitytName",
            },
            {
                label: "تاريخ الطلب",
                field: "dateCreated",
            },
            {
                label: "تاريخ الاستجابة",
                field: "responseDate",
            },
            {
                label: "رقم الجوال",
                field: "phoneNumber",
            },
            {
                label: "الصفة",
                field: "userType",
            },
            {
                label: "تفاصيل",
                field: "details",
                sortable: false,
            },
        ],
    }),
    methods: {
        ...mapMutations([
            "Update_Orders_Active_Tab",
        ]),
        setNotificationTabActive() {
            this.Update_Orders_Active_Tab(0);
        },
        setAdsTabActive() {
            this.Update_Orders_Active_Tab(1);
        },
        setJobTabActive() {
            this.Update_Orders_Active_Tab(2);
        },
        beforeDestroy() {
            this.$store.commit("Reset_Search_Dto");
        },
    },
};
</script>

<style lang="scss" scoped>
.orders-title-filtering-area {
    &__container {
        @media only screen and (max-width: 991px) {
            flex-direction: column;
            gap: 1rem;
        }
    }

    &__filter-button {
        width: 100%;
        font-size: 1.1rem;

        letter-spacing: 0.4px;
    }
}


</style>
