<template>
    <div class="job-orders">
        <section class="job-orders-filtering-area mb-2">
            <b-row align-v="center">
                <b-col lg="1">
                    <div class="job-orders-filtering-area__filter-title mb-1">
                        فلتر حسب:
                    </div>
                </b-col>

                <b-col
                    lg="4"
                    md="6"
                    xs="6"
                    class="job-orders-filtering-area__filter-by-activity-container d-flex w-100"
                >
                    <div
                        class="job-orders-filtering-area__activity-filter-title mr-1"
                    >
                        اسم الفعالية:
                    </div>
                    <ek-input-select
                        v-model="filter.senderId"
                        clearable
                        :options="activitiesList"
                        name="filterStartDate"
                        class="job-orders-filtering-area__activity-filter-input"
                    />
                </b-col>

                <b-col
                    lg="4"
                    md="6"
                    xs="6"
                    class="job-orders-filtering-area__filter-by-order-date-container d-flex"
                >
                    <div
                        class="job-orders-filtering-area__order-date-title mr-1"
                    >
                        تاريخ الطلب:
                    </div>
                    <ek-date-picker
                        v-model="filter.dateRange"
                        range
                        name="تاريخ الطلب"
                        class="job-orders-filtering-area__order-date-input"
                    />
                </b-col>
                <b-col
                    lg="3"
                    md="6"
                    xs="6"
                    class="job-orders-filtering-area__filter-by-order-status-container d-flex"
                >
                    <div
                        class="job-orders-filtering-area__order-status-title mr-1"
                    >
                        حالة الطلب:
                    </div>
                    <ek-input-select
                        clearable
                        v-model="filter.status"
                        :options="orderStatusList"
                        name="statusFtiler"
                        class="job-orders-filtering-area__order-status-input"
                    />
                </b-col>
            </b-row>
        </section>

        <section class="orders-table">
            <ek-table
                :items="jobOrdersList.filter(filterJob)"
                :columns="columns"
                @delete-selected="deleteJobs"
                @details="navigateToJobOrderDetails"
            >
                <template slot="items.dateCreated" slot-scope="{ value }">
                    {{
                        value
                            ? new Date(value)
                                  .toLocaleString("en-UK")
                                  .split(",")
                                  .reverse()
                                  .join(" ")
                            : "-"
                    }}
                </template>
                <template slot="items.responseDate" slot-scope="{ value }">
                    {{
                        value
                            ? new Date(value)
                                  .toLocaleString("en-UK")
                                  .split(",")
                                  .reverse()
                                  .join(" ")
                            : "-"
                    }}
                </template>
                <template slot="items.dashResponse" slot-scope="{ value }">
                    <StatusBadge
                        class="orders-table__status-badge"
                        :statusList="orderStatusList"
                        :selectedStatusNumber="value"
                    />
                </template>
            </ek-table>
        </section>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge";
import { dateManipulatoin } from "@core/mixins/ui/dateManipulatoin";

export default {
     mixins: [dateManipulatoin],

    computed: {
        ...mapGetters(["jobOrdersList"]),
        ...mapState({
            orderStatusList: (state) => state.orders.orderStatusList,
            activitiesList: ({ activities }) => activities.activities,
        }),
    },
    components: {
        StatusBadge,
    },
    data: () => ({
        filter: {
            dateRange: "",
            status: null,
            senderId: null,
        },
        columns: [
            {
                label: " اسم الفعالية",
                field: "senderName",
            },
            {
                label: "النشاط التجاري",
                field: "commertialActivityName",
            },
            {
                label: "المسمى الوظيفي",
                field: "name",
            },
            {
                label: "تاريخ الطلب",
                field: "dateCreated",
            },
            {
                label: "تاريخ الاستجابة",
                field: "responseDate",
            },
            {
                label: "حالة الطلب",
                field: "dashResponse",
            },
            {
                label: "تفاصيل",
                field: "details",
                sortable: false,
            },
        ],
    }),
    created() {
        this.getJobOrders();
    },
    methods: {
        ...mapActions(["getJobOrders", "deleteRangeJobOrders"]),
        ...mapMutations(["Reset_Orders_Dto"]),
        navigateToJobOrderDetails({ row }) {
            this.$router.push(`/admin/orders/jobRequest/${row.id}`);
        },
        formatOrdersDate(startDate, endDate) {
            return `${new Date(startDate).toLocaleDateString()}-${new Date(
                endDate
            ).toLocaleDateString()}`;
        },
        navigateToOrdersDetails(id) {
            this.$router.push(`/admin/orders/${id}`);
        },
        filterJob(item) {
            let [start = null, end = null] = this.filter.dateRange
                ?.replace("to", "")
                .replace(" ", "")
                .split(" ");

            return (
                ((this.setDateTime(new Date(item.dateCreated)) >=
                    this.setDateTime(new Date(start)) &&
                    this.setDateTime(new Date(item.dateCreated)) <=
                    this.setDateTime(new Date(end))) ||
                    this.filter.dateRange.length == 0) &&
                (item.dashResponse == this.filter.status ||
                    !this.filter.status) &&
                (item.senderId == this.filter.senderId || !this.filter.senderId)
            );
        },
        beforeDestroy() {
            this.$store.commit("Reset_Search_Dto");
        },
        deleteJobs(e) {

        let removeList = e.map(item => ({id: item, requestTypes: 3}))
        
        this.deleteRangeJobOrders(removeList)
        
    },
    },
};
</script>

<style lang="scss" scoped>
.job-orders-filtering-area {
    align-items: center;

    @media only screen and (max-width: 780px) {
        flex-direction: column;
    }

    &__filter-title {
        font-weight: bold;
        font-size: 1.2rem;

        color: #6e6b7b;
    }

    &__filter-container {
        display: flex;
        gap: 2rem;
    }

    &__filter-by-activity-container {
        display: flex;
    }

    &__activity-filter-title {
        margin-top: 1.4rem;
        font-size: 1.2rem;

        color: #6e6b7b;
    }

    &__activity-filter-input {
        flex: 1;
    }

    &__filter-by-orders-status-container {
        display: flex;
    }

    &__filter-by-order-date-container {
        margin-top: 0.6rem;
    }

    &__filter-by-order-status-container {
        margin-top: -0.5rem;
    }

    &__order-date-title {
        margin-top: 0.6rem;

        font-size: 1.2rem;

        color: #6e6b7b;

        @media only screen and (max-width: 780px) {
            margin-left: 0px;
        }
    }

    &__order-date-input {
        margin-top: -0.9rem;
        flex: 1;
    }

    &__order-status-title {
        margin-top: 1.4rem;

        font-size: 1.2rem;

        color: #6e6b7b;

        @media only screen and (max-width: 780px) {
            margin-left: 0px;
        }
    }

    &__order-status-input {
        flex: 1;
    }
}
</style>
